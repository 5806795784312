import styles from "./voucher.module.css";
import { VoucherInfo } from "../../components/voucherInfo/voucherInfo";
import {
  useCheckVoucherHook,
  VoucherCheckStatus,
} from "../../hooks/useCheckVoucher.hook";
import { ClaimModal } from "../../components/claimModal/claimModal";

export const VoucherPage = () => {
  const { status, voucherData, claim } = useCheckVoucherHook();

  return (
    <div className={styles.content}>
      <img
        className={styles.background}
        src="/voucher-background.png"
        alt="Background"
      />
      <img className={styles.header} src="/voucher-banner.png" alt="Voucher" />

      <VoucherInfo
        visible={status === VoucherCheckStatus.CLAIMED}
        data={voucherData}
      />

      <h1 className={styles.title}>
        یک وعده <span>رایگان</span>
      </h1>
      <span className={styles.subtitle}>همبرگر + سیب‌زمینی + نوشابه</span>

      <div className={styles.info}>
        <h5>
          <img src="/coochini-agelimit.svg" alt="Age limit" />
          در رستوران ۲۴ ساعته کوچینی
        </h5>
        <hr />
        <span>همراه با موسیقی زنده</span>
        <span className={styles.time}>۱۰ شب - ۴ صبح</span>
        <span className={styles.kids}>
          (پذیرایی از کودکان خارج از رستوران انجام می‌شود)
        </span>
      </div>
      <div className={styles.address}>
        <span>خیابان آل مکتوم، هتل خلیج پالاس دیره، رستوران کوچینی</span>
        <span className={styles.english}>
          Al Maktoum Rd, Khaleej Palaced Deira Hotel, Coochini Restaurant
        </span>

        <a
          href="https://maps.app.goo.gl/c2PdK8C545mDCmX68"
          target="_blank"
          className={styles.navigationButton}
          rel="noreferrer"
        >
          <img src="/arrow-left.svg" alt="left arrow" />
          مسیریابی در گوگل مپ
        </a>
      </div>

      <img
        src="/coochini-logo.png"
        alt="Coochini Logo"
        className={styles.logo}
      />

      <ClaimModal
        visible={status === VoucherCheckStatus.UNCLAIMED}
        submit={claim}
      />
    </div>
  );
};
