import styles from "./claimModal.module.css";
import { Input } from "../input/input";
import { FormEvent, useState } from "react";
import { Button } from "../button/button";

export interface ClaimModalProps {
  visible?: boolean;

  submit?: (fullName: string, phoneNumber: string) => void;
}

export const ClaimModal = (props: ClaimModalProps) => {
  const [fullName, setFullName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const [errors, setErrors] = useState({
    fullName: "",
    phoneNumber: "",
  });

  const handleSubmit = (event?: FormEvent<HTMLFormElement>) => {
    event?.preventDefault();

    if (!fullName || !phoneNumber) {
      setErrors({
        fullName: !fullName ? "Please enter your full name" : "",
        phoneNumber: !phoneNumber ? "Please enter your phone number" : "",
      });
      return;
    }

    if (!props.submit) {
      return;
    }

    props.submit(fullName, phoneNumber);
  };

  if (!props.visible) {
    return null;
  }

  return (
    <div className={styles.container}>
      <form className={styles.modal} onSubmit={handleSubmit}>
        <span className={styles.title}>
          Please enter your full name and phone number to claim your voucher
        </span>
        <Input
          value={fullName}
          onChange={setFullName}
          placeholder="Full Name"
          inputMode="text"
          type="text"
        />
        {errors.fullName && (
          <span className={styles.error}>{errors.fullName}</span>
        )}

        <Input
          value={phoneNumber}
          onChange={setPhoneNumber}
          placeholder="Phone Number"
          type="text"
          inputMode="tel"
        />

        {errors.phoneNumber && (
          <span className={styles.error}>{errors.phoneNumber}</span>
        )}

        <Button
          text="Claim"
          className={styles.button}
          type="submit"
          onClick={() => handleSubmit()}
        />
      </form>
    </div>
  );
};
