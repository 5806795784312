import { useEffect, useState } from "react";
import { API } from "../common/api";
import { AxiosError } from "axios";
import { useVoucherInfo } from "./useVoucherInfo.hook";

export enum VoucherStatus {
  WAIT_FOR_PASSWORD = "WAIT_FOR_PASSWORD",
  LOADING = "LOADING",
  VALID = "VALID",
  INVALID = "INVALID",
  EXPIRED = "EXPIRED",
  INVALID_PASSWORD = "INVALID_PASSWORD",
}

export const useVoucher = () => {
  const { voucher, tenant, code } = useVoucherInfo();

  const [voucherStatus, setVoucherStatus] = useState<VoucherStatus>(
    VoucherStatus.WAIT_FOR_PASSWORD,
  );

  const [password, setPassword] = useState<string | null>(null);

  const [trigger, setTrigger] = useState(false);

  const validateVoucher = (password: string) => {
    setPassword(password);
    setTrigger(true);
  };

  useEffect(() => {
    if (!voucher || !code || !tenant) {
      setVoucherStatus(VoucherStatus.INVALID);
      return;
    }

    if (
      ![
        VoucherStatus.WAIT_FOR_PASSWORD,
        VoucherStatus.INVALID_PASSWORD,
      ].includes(voucherStatus)
    ) {
      return;
    }

    if (!password) {
      setVoucherStatus(VoucherStatus.WAIT_FOR_PASSWORD);
      return;
    }

    if (!trigger) {
      return;
    }

    setVoucherStatus(VoucherStatus.LOADING);

    // API call to validate voucher
    API.validateVoucher(tenant, voucher, code, password)
      .then((response) => {
        if (response.status === 200) {
          setVoucherStatus(VoucherStatus.VALID);
          return;
        }
      })
      .catch(
        ({
          status,
          response,
        }: AxiosError<{ errors: { messages: string[] }[] }>) => {
          if (
            status === 400 &&
            response?.data &&
            Array.isArray(response.data.errors) &&
            Array.isArray(response.data.errors[0]?.messages)
          ) {
            const errorMessage = response.data.errors[0]?.messages[0];
            if (errorMessage === "Code already used") {
              setVoucherStatus(VoucherStatus.EXPIRED);
              return;
            } else if (errorMessage === "Invalid password") {
              setVoucherStatus(VoucherStatus.INVALID_PASSWORD);
              setTrigger(false);
              return;
            }
          }
          setVoucherStatus(VoucherStatus.INVALID);
          return;
        },
      );
  }, [voucher, code, tenant, password, trigger, voucherStatus]);

  return { voucherStatus, validateVoucher };
};
