import Axios, { AxiosInstance } from "axios";

export class API {
  private static BASE_URL = process.env.REACT_APP_API_BASE_URL;

  private static axios: AxiosInstance;

  private static getInstance(): AxiosInstance {
    if (API.axios) {
      return API.axios;
    }

    API.axios = Axios.create({
      baseURL: API.BASE_URL,
      headers: {
        "Content-Type": "application/json",
      },
    });

    return API.axios;
  }

  static async validateVoucher(
    tenant: string,
    voucherId: string,
    voucherCode: string,
    password: string,
  ) {
    return API.getInstance().patch(
      `/marketing/vouchers/${voucherId}/codes/${voucherCode}/use`,
      {
        password: password,
      },
      {
        headers: {
          tenant: tenant,
        },
      },
    );
  }

  static async checkVoucher(
    tenant: string,
    voucherId: string,
    voucherCode: string,
  ) {
    return API.getInstance().get(
      `/marketing/vouchers/${voucherId}/codes/${voucherCode}`,
      {
        headers: {
          tenant: tenant,
        },
      },
    );
  }

  static async claimVoucher(
    tenant: string,
    voucherId: string,
    voucherCode: string,
    data: {
      fullName: string;
      phoneNumber: string;
    },
  ) {
    return API.getInstance().patch(
      `/marketing/vouchers/${voucherId}/codes/${voucherCode}/claim`,
      {
        fullName: data.fullName,
        phoneNumber: data.phoneNumber,
      },
      {
        headers: {
          tenant: tenant,
        },
      },
    );
  }
}
