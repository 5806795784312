import { Header } from "../header/header";
import { Container } from "../container/container";
import { ReactNode } from "react";

export interface ITemplateProps {
  children: ReactNode;
}

export const Template = (props: ITemplateProps) => {
  return (
    <>
      <Header />
      <Container>{props.children}</Container>
    </>
  );
};
