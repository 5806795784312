import styles from "./input.module.css";
import { ChangeEvent } from "react";
import classNames from "classnames";

export interface IInputProps {
  placeholder?: string;
  value: string;
  onChange?: (value: string) => void;
  type?: "text" | "password" | "number";
  className?: string;
  pattern?: string;
  inputMode?: "text" | "numeric" | "tel";
  onFocus?: () => void;
}

export const Input = (props: IInputProps) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.onChange?.(e.target.value);
  };

  return (
    <input
      value={props.value}
      placeholder={props.placeholder}
      onChange={onChange}
      className={classNames(styles.input, props.className)}
      type={props.type}
      pattern={props.pattern}
      inputMode={props.inputMode}
      onFocus={props.onFocus}
    />
  );
};
