import { useVoucher, VoucherStatus } from "../../hooks/useVoucher.hook";
import styles from "./validation.module.css";
import { Button } from "../../components/button/button";
import { Input } from "../../components/input/input";
import { FormEvent, useState } from "react";
import classNames from "classnames";
import { Template } from "../../components/template/template";

export const ValidationPage = () => {
  const { voucherStatus, validateVoucher } = useVoucher();
  const [password, setPassword] = useState<string>("");

  const icon =
    voucherStatus === VoucherStatus.VALID ? "/check.svg" : "/warning.svg";

  const text =
    voucherStatus === VoucherStatus.INVALID_PASSWORD
      ? "Invalid password! Try again."
      : voucherStatus === VoucherStatus.LOADING
        ? "Loading..."
        : voucherStatus === VoucherStatus.INVALID
          ? "The voucher is invalid!"
          : voucherStatus === VoucherStatus.EXPIRED
            ? "The voucher has expired!"
            : "The voucher is valid!";

  const submitVoucher = (event?: FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    if (password.length === 0) {
      return;
    }
    validateVoucher(password);
  };

  return (
    <Template>
      <div className={styles.content}>
        {![
          VoucherStatus.LOADING,
          VoucherStatus.WAIT_FOR_PASSWORD,
          VoucherStatus.INVALID_PASSWORD,
        ].includes(voucherStatus) && (
          <img alt="status" src={icon} className={styles.icon} />
        )}
        {voucherStatus !== VoucherStatus.WAIT_FOR_PASSWORD && (
          <span
            className={classNames({
              [styles.message]: true,
              [styles.passwordPrompt]: [
                VoucherStatus.INVALID_PASSWORD,
                VoucherStatus.WAIT_FOR_PASSWORD,
              ].includes(voucherStatus),
              [styles.invalid]:
                voucherStatus === VoucherStatus.INVALID ||
                voucherStatus === VoucherStatus.EXPIRED,
              [styles.valid]: voucherStatus === VoucherStatus.VALID,
            })}
          >
            {text}
          </span>
        )}
        {[
          VoucherStatus.WAIT_FOR_PASSWORD,
          VoucherStatus.INVALID_PASSWORD,
        ].includes(voucherStatus) && (
          <form
            onSubmit={submitVoucher}
            className={styles.validationForm}
            noValidate
            method=""
          >
            <Input
              value={password}
              placeholder="Enter password"
              onChange={setPassword}
              type="password"
              className={styles.input}
              pattern="[0-9]*"
              inputMode="numeric"
            />
            <Button
              text="Validate Voucher"
              onClick={() => submitVoucher()}
              type="submit"
              className={styles.submit}
            />
          </form>
        )}
      </div>
    </Template>
  );
};
