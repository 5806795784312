import React from "react";
import styles from "./container.module.css";

export const Container = (props: { children: React.ReactNode }) => {
  return (
    <>
      <div className={styles.container}>{props.children}</div>
      <a
        href="https://www.fabizi.com"
        target="_blank"
        className={styles.footer}
        rel="noreferrer"
      >
        <img src="/footer-logo.svg" alt="fabizi" />
      </a>
    </>
  );
};
