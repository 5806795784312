import styles from "./voucherInfo.module.css";
import QRCode from "qrcode";
import { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { VoucherData } from "../../common/types/voucherData.type";
import { useVoucherInfo } from "../../hooks/useVoucherInfo.hook";

export interface VoucherInfoProps {
  visible?: boolean;

  data: VoucherData | null;
}

export const VoucherInfo = (props: VoucherInfoProps) => {
  const { voucher, code, tenant } = useVoucherInfo();
  const [qrUrl, setQrUrl] = useState<string | null>(null);

  const url = useMemo(
    () =>
      `https://voucher.fabizi.com/${tenant}/${voucher}/validate?code=${code}`,
    [voucher, code, tenant],
  );

  useEffect(() => {
    QRCode.toDataURL(url, {
      width: 150,
      scale: 50,
      margin: 0,
      color: { dark: "#ffffff", light: "#00000000" },
      errorCorrectionLevel: "low",
    }).then((url) => setQrUrl(url));
  }, [url]);

  if (!props.data || !qrUrl) {
    return null;
  }

  return (
    <div
      className={classNames(styles.voucherInfo, {
        [styles.hidden]: !props.visible,
      })}
    >
      <img className={styles.qrContainer} src={qrUrl} alt="qr code" />
      <span className={styles.ticketNumber}>
        Ticket No. {String(props.data.ticketNumber).padStart(4, "0")}
      </span>
    </div>
  );
};
