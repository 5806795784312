import { useEffect } from "react";
import styles from "./error.module.css";
import { Template } from "../../components/template/template";

export const ErrorPage = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      return;
    }
    setTimeout(() => {
      window.location.href = "https://fabizi.com";
    }, 4000);
  }, []);

  return (
    <Template>
      <div className={styles.content}>
        <span>Not Found!</span>
        <p>You'll be redirected to Fabizi's home page in a few seconds.</p>
      </div>
    </Template>
  );
};
