import { useVoucherInfo } from "./useVoucherInfo.hook";
import { useState } from "react";
import { API } from "../common/api";

export enum VoucherClaimStatus {
  LOADING = "LOADING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export const useClaimVoucher = () => {
  const { voucher, tenant, code } = useVoucherInfo();
  const [status, setStatus] = useState<VoucherClaimStatus | null>(null);

  const claimVoucher = (fullName: string, phoneNumber: string) => {
    if (!voucher || !tenant || !code) {
      return;
    }

    setStatus(VoucherClaimStatus.LOADING);

    API.claimVoucher(tenant, voucher, code, { fullName, phoneNumber })
      .then((response) => {
        if (response.status === 200) {
          setStatus(VoucherClaimStatus.SUCCESS);
        }
      })
      .catch(() => {
        setStatus(VoucherClaimStatus.ERROR);
      });
  };

  return { status, claimVoucher };
};
