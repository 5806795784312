import styles from "./button.module.css";
import { MouseEventHandler } from "react";
import classNames from "classnames";

export interface IButtonProps {
  text: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: "button" | "submit" | "reset";
  className?: string;
}

export const Button = (props: IButtonProps) => {
  return (
    <button
      className={classNames(styles.button, props.className)}
      onClick={props.onClick}
      type={props.type}
    >
      {props.text}
    </button>
  );
};
