import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ValidationPage } from "./pages/validation/validation.page";
import { ErrorPage } from "./pages/error/error.page";
import { VoucherPage } from "./pages/voucher/voucher.page";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:tenant/:voucher/validate" element={<ValidationPage />} />
        <Route path="/:tenant/:voucher" element={<VoucherPage />} />
        <Route path="/*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
