import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "../common/api";
import { VoucherData } from "../common/types/voucherData.type";
import { AxiosError } from "axios";
import { useVoucherInfo } from "./useVoucherInfo.hook";
import { useClaimVoucher, VoucherClaimStatus } from "./useClaimVoucher.hook";

export enum VoucherCheckStatus {
  LOADING = "LOADING",
  INVALID = "INVALID",
  UNCLAIMED = "UNCLAIMED",
  SUBMITTING = "SUBMITTING",
  CLAIMED = "CLAIMED",
}

export const useCheckVoucherHook = () => {
  const navigate = useNavigate();
  const { voucher, tenant, code } = useVoucherInfo();

  const [status, setStatus] = useState<VoucherCheckStatus>(
    VoucherCheckStatus.LOADING,
  );
  const [voucherData, setVoucherData] = useState<VoucherData | null>(null);

  const [trigger, setTrigger] = useState<number>(0);
  const { status: claimStatus, claimVoucher } = useClaimVoucher();

  const reload = () => setTrigger((prev) => prev + 1);

  const claim = (fullName: string, phoneNumber: string) => {
    if (!voucher || !tenant || !code) {
      return;
    }

    setStatus(VoucherCheckStatus.SUBMITTING);
    claimVoucher(fullName, phoneNumber);
  };

  useEffect(() => {
    if (claimStatus === VoucherClaimStatus.SUCCESS) {
      reload();
    }
  }, [claimStatus]);

  useEffect(() => {
    if (status === VoucherCheckStatus.INVALID) {
      navigate("/");
    }
  }, [status, navigate]);

  useEffect(() => {
    if (!voucher || !code || !tenant) {
      navigate("/");
      return;
    }

    setStatus(VoucherCheckStatus.LOADING);

    API.checkVoucher(tenant, voucher, code)
      .then((response) => {
        if (response.status === 200) {
          setStatus(VoucherCheckStatus.CLAIMED);
          setVoucherData(response.data.data);
        }
      })
      .catch(
        ({
          status,
          response,
        }: AxiosError<{ errors: { messages: string[] }[] }>) => {
          if (
            status === 400 &&
            response?.data &&
            Array.isArray(response.data.errors) &&
            Array.isArray(response.data.errors[0]?.messages) &&
            response.data.errors[0]?.messages[0] === "Not claimed"
          ) {
            setStatus(VoucherCheckStatus.UNCLAIMED);
          } else {
            setStatus(VoucherCheckStatus.INVALID);
          }
        },
      );
  }, [tenant, voucher, code, trigger, navigate]);

  return { status, voucherData, claim };
};
