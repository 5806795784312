import { useParams, useSearchParams } from "react-router-dom";

export const useVoucherInfo = () => {
  const { voucher, tenant } = useParams();

  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  return { voucher, tenant, code };
};
